import React, { useEffect, useState } from 'react'
import UserData from "../../../fakeData/UserData.js";
import { Reshtey } from '../../../Components/index.js';
import male from '../../../assets/matrimonyCard/malematricardview.png'
import female from '../../../assets/matrimonyCard/femalematricardview.png'
import { Link } from 'react-router-dom';
import FormButton from '../../../Forms/AdminButtons.js';
// import { MembersCard, Pagination } from '../../../Components/index.js';
import Card from '../../../Components/Card.js';
import { get_member, searchMemberForMemberPage } from '../../../ApiConfig.js';
import axios from 'axios';
import { SearchCriteria, SearchDropDownCriteria } from '../../../FormsInputes.js/index.js';
import useIntersectionObserver from '../../../Helper/useIntersectionObserver.js';
import { storePagination } from '../../../service/sessionStor.js';
import { getMatrimony, getMember } from '../../../service/Services.js';
import { useDispatch, useSelector } from 'react-redux';
import { setPagination } from '../../../Redux/paginationSlicer.js';
import { gotra } from "../../../constants/staticData.js"
import ImageCarousel from '../../../Components/carousel.js';

import { occupations, ageRange } from "../../../constants/staticData.js"




export default function Rishtey() {

    const [currentPage, setCurrentPage] = useState(1);
    const [criteria, setCriteria] = useState({ name: "", surname: "", gotra: "", cityName: '', cityId: '', occupation: "", gender: "", minAge: "", maxAge: "" })
    const [byName, setByName] = useState([])
    const [city, setCity] = useState([])
    // const [occupation, setOccupation] = useState([])
    const [surename, setSurname] = useState([])
    // const [gotra, setGotra] = useState(gotra)
    const [cancelToken, setCancelToken] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [members, setMember] = useState([]);
    // const [paginationData, setPagination] = useState({})
    // const [totalPages, setTotalPages] = useState(0);

    const paginationData = useSelector(state => state?.pagination?.pagination)
    const lastItemRef = useIntersectionObserver({ currentPage, paginationData, setCurrentPage });
    const totalItemInPage = 10;

    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true)
        const source = axios.CancelToken.source();
        setCancelToken(source);
        let delayDebounceFn;


        delayDebounceFn = setTimeout(async () => {
            try {
                const headers = {
                    'Content-Type': 'application/json'
                };
                const requestOptions = {
                    headers: headers,
                    withCredentials: true, // Include credentials if necessary
                    params: {
                        page: currentPage,
                        limit: totalItemInPage
                    }
                };
                const { name, surname, gotra, gender, minAge, maxAge, occupation, ...cityData } = criteria;

                // console.log('criteria', criteria)
                const memberData = await getMatrimony({ name, surname, gotra, gender, minAge, maxAge, occupation, city: cityData?.city?.id }, requestOptions)
                // const memberData = await axios.post(`${searchMemberForMemberPage}`, { name, surname, gotra, occupation, city: cityData?.city?.id }, requestOptions);
                console.log('searchMemberForMemberPage', memberData);
                if (memberData?.success) {
                    const data = memberData?.data || [];
                    // setTotalPages(memberData?.pagination?.totalPages);
                    setPagination(memberData?.pagination)
                    dispatch(setPagination(memberData?.pagination))
                    // storePagination(memberData?.pagination)
                    // console.log(memberData?.pagination)
                    setMember(pre => [...pre, ...data])
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false)
            }
        }, 500);

        return () => {
            clearTimeout(delayDebounceFn);
            source.cancel('Request canceled because a new request was made');
        };
        // fetchData();
    }, [currentPage, criteria])

    useEffect(() => {
        setMember([])
        setPagination({})
        setCurrentPage(pre => 1)
    }, [currentPage, criteria])

    // useEffect(() => {
    //     const uniqueNames = [...new Set(members.map(member => member.name))];
    //     setByName(uniqueNames);
    //     // console.log(uniqueNames)
    //     // const uniqueCities = Array.from(new Set(members.map(member => member.city.id)))
    //     //     .map(cityId => members.find(member => member.city.id === cityId).city);
    //     // setCity(uniqueCities);
    //     const uniqueSurnames = [...new Set(members.map(member => member.surname))];
    //     setSurname(uniqueSurnames);
    //     // const uniqueGotras = [...new Set(members.map(member => member.gotra))];
    //     // setGotra(uniqueGotras);
    //     const uniqueOccupations = [...new Set(members.map(member => member.occupation))];
    //     setOccupation(uniqueOccupations);
    // }, [members.length])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };



    return (
        <>

            {/* <div className=' w-full flex justify-end'>
            <Link className=" btn bg-blue-900 mt-10 text-white font-extrabold" to='/dashboard/matrimony/registration'>Add Matrimony</Link>
            </div> */}
            {/* text, className, url,  */}
            {/* <FormButton text="विवाह रजिस्ट्रेशन करें" className=" bg-blue-900  text-white font-extrabold" url="/dashboard/matrimony/registration" /> */}
            {/* <div className='  flex flex-wrap gap-2 justify-center'>
                {members.map((member, index) => (
                    <div key={index}>
                        <img className=' max-w-[400px]' src={index % 2 === 0 ? male : female} alt="Avatar" />
                        <Reshtey rishtey={member} key={index} style={{ color: "red" }} />
                    </div>
                ))}

            </div> */}

            <div className='flex h-100    flex-wrap flex-col xl:flex-row gap-2 justify-center'>

                <div className="  flex flex-col md:flex-row justify-center items-center flex-wrap  gap-5">
                    <div className="   ">
                        {/* name */}
                        <SearchCriteria
                            options={byName}
                            search={'name'}
                            id={'name'}
                            setOptions={setCriteria}
                            label={"नाम"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={'  text-black font-bold'}

                        />
                    </div>

                    {/* surename */}
                    <div className="w-100  ">
                        <SearchCriteria
                            options={surename}
                            search={'surname'}
                            id={'surname'}
                            setOptions={setCriteria}
                            label={"उपनाम"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div>

                    {/* gotra */}
                    <div className="w-100  ">
                        <SearchCriteria
                            options={gotra}
                            search={'gotra'}
                            id={'gotra'}
                            setOptions={setCriteria}
                            label={"गोत्र"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div>

                    {/* Gender */}
                    <div className="w-100  ">
                        <SearchCriteria
                            options={["Male", "Female"]}
                            search={'gender'}
                            id={'gender'}
                            setOptions={setCriteria}
                            label={"Gender"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div>

                    {/* minAge && maxAge */}
                    <div className="w-100  ">
                        <SearchCriteria
                            options={ageRange}
                            search={'minAge'}
                            id={'minAge'}
                            setOptions={setCriteria}
                            label={"किस उम्र से  "}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div>

                    <div className="w-100  ">
                        <SearchCriteria
                            options={ageRange}
                            search={'maxAge'}
                            id={'maxAge'}
                            setOptions={setCriteria}
                            label={"किस उम्र तक"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div>

                    {/* city */}
                    <div className="w-100  ">
                        <SearchCriteria
                            options={city}
                            search={'city'}
                            id={'city'}
                            setOptions={setCriteria}
                            label={"शहर"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                        {/* // Pass the register function as a prop */}

                    </div>

                    {/* occupation */}
                    <div className="w-100  ">
                        <SearchCriteria
                            options={occupations}
                            search={'occupation'}
                            id={'occupation'}
                            setOptions={setCriteria}
                            label={"व्यवसाय"}
                            className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                            style={' text-black font-bold'}
                        />
                    </div>
                </div>

                <div className="flex flex-col items-center justify-center my-3   bg-gray-100">
                    <ImageCarousel />
                </div>

                {/* <div className="flex flex-col items-center justify-center   bg-gray-100">
                    <ImageCarousel />
                </div> */}

                <div className="flex w-full flex-wrap gap-2 justify-center">
                    {members?.map((member, index) => (
                        (members.length === index + 1) ?
                            <div key={index} className='thi si last index' ref={lastItemRef} ><Reshtey rishtey={member} key={index} style={{ color: "red" }} /> </div> : <div key={index} > <Reshtey rishtey={member} key={index} style={{ color: "red" }} /> </div>
                    ))}
                </div>
                {isLoading && <div className="flex items-center justify-center">
                    <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
                </div>}
                {!paginationData?.hasNextPage && <h1 className=' text-center'>End</h1>}

                {/* <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
            </div>
            {/* </div> */}

        </>
    )
}
