import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FormButton, PublicButtons } from '../../../Forms'
import { getSuggessionById } from '../../../service/Services';
import SuggetionsCardForUser from "../../../cards/sugetionCardForUser"

export default function Suzhav() {

    const [suggetions, setSuggetions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getAllSuggetions = async () => {
            try {
                const response = await getSuggessionById();

                // console.log('response', response)

                if (response?.success) {
                    setSuggetions(response?.data);
                }
                // setSuggetions(response?.data);
            } catch (err) {
                setError('An error occurred while fetching suggestions');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        getAllSuggetions();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <>
            <PublicButtons text="नया सुझाव जोड़े" className=" bg-blue-950 text-white" url="/dashboard/newSuzhav" />

            <div className=' w-full flex flex-wrap justify-center'>
                {suggetions.map((suggetion, index) => (
                    <div key={index}>
                        <SuggetionsCardForUser suggetions={suggetion} />
                    </div>
                ))}
            </div>
        </>
    );
}



