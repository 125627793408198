import React, { useEffect, useRef } from 'react';

const CameraStream = ({ gateway, port, path }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            // Construct the URL to your camera stream
            const cameraUrl = `http://${gateway}:${port}/${path}`;

            // Set the video element's source to the camera URL
            videoElement.src = cameraUrl;

            // If the video element supports playing, add the event listener
            videoElement.addEventListener('loadedmetadata', () => {
                videoElement.play();
            });

            return () => {
                videoElement.pause();
                videoElement.removeEventListener('loadedmetadata', () => {
                    videoElement.play();
                });
            };
        }
    }, [gateway, port, path]);

    return (
        <div className="camera-container">
            <video ref={videoRef} controls className="w-full h-auto border border-gray-300 rounded-lg"></video>
        </div>
    );
};

export default CameraStream;
