import React, { useEffect, useRef } from 'react'
import { deleteIntrestRequest, getIntrestedProfileBothSide } from '../../../service/Services';
import { useState } from 'react';
import { IntrestCard, } from '../../../cards';
import { showErrorAlert, showSuccessAlert } from '../../../Components/ShowAlertCustome';
import LoadingModal from '../../../Forms/LoadingModel';
import { Domain } from '../../../ApiConfig';
import { useSelector } from 'react-redux';
import { gotra } from '../../../constants/staticData';
import { useReactToPrint } from 'react-to-print';
// import { useReactToPrint } from 'react-to-print';

export default function IntrestPage() {
    const [page, setPage] = useState('Sended');
    const [sendProfile, setSendProfile] = useState([]);
    const [recivedProfile, setRecivedProfile] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteIntrestById, setDeleteIntrestById] = useState(null);
    const user = useSelector(state => state?.auth?.user)


    const gentIntrest = async () => {
        try {
            setLoading(true);
            const getProfiles = await getIntrestedProfileBothSide();
            const { sendedByYou, recivedByYou } = getProfiles?.data;
            setSendProfile(sendedByYou);
            setRecivedProfile(recivedByYou);
        } catch (error) {
            console.error('Error fetching profiles:', error);
            showErrorAlert('Error fetching profiles. Please try again later.');
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        gentIntrest();
    }, []);

    useEffect(() => {
        const deleteRequest = async () => {
            try {
                setLoading(true);
                const isRequestDelete = await deleteIntrestRequest(deleteIntrestById);
                if (isRequestDelete.success) {
                    gentIntrest();
                    showSuccessAlert('Request deleted');
                }
            } catch (error) {
                console.log("Error:", error);
                showErrorAlert('Error deleting request. Please try again later.');
            } finally {
                setLoading(false);
                setDeleteIntrestById(null);
            }
        };

        if (deleteIntrestById !== null) {
            deleteRequest();
        }
    }, [deleteIntrestById]);

    const RenderPage = () => {
        switch (page) {
            case "Sended":
                return <>
                    <Profile profiles={sendProfile} text={'sendedByYou'} setDeleteIntrestById={setDeleteIntrestById} />
                    {sendProfile.length === 0 && <h1>No Data</h1>}
                </>;
            case "Recived":
                return <>
                    <Profile profiles={recivedProfile} text={'recivedByYou'} />
                    {recivedProfile.length === 0 && <h1>No Data</h1>}
                </>;
            case "Reports":
                return <><Reports sended={sendProfile} recived={recivedProfile} user={user} /></>;

            default:
                return null;
        }
    };

    if (loading) {
        return <LoadingModal show={loading} text='Loading' />;
    }

    return (
        <>
            <div className='my-4 w-full gap-3 flex justify-around flex-wrap'>
                <button
                    onClick={() => setPage('Sended')}
                    className={`cursor-pointer shadow-2xl rounded-lg px-10 py-1 w-72 font-bold text-center ${page === "Sended" ? 'text-white bg-blue-600' : 'text-black bg-red-100 border-red-100'}`}
                >
                    आपके पसंदीदा रिश्ते
                </button>
                <button
                    onClick={() => setPage("Recived")}
                    className={`cursor-pointer shadow-2xl rounded-lg px-10 py-1 w-72 font-bold text-center ${page === "Recived" ? 'text-white bg-blue-600' : 'text-black bg-red-100 border-red-100'}`}
                >
                    आपको पसंद करने वाले रिश्ते
                </button>
                <button
                    onClick={() => setPage("Reports")}
                    className={`cursor-pointer shadow-2xl rounded-lg px-10 py-1 w-72 font-bold text-center ${page === "Reports" ? 'text-white bg-blue-600' : 'text-black bg-red-100 border-red-100'}`}
                >
                    रिपोर्टस
                </button>
            </div>
            <div className='mb-5'>
                <RenderPage />
            </div>
        </>
    );
}

const Profile = ({ profiles, text, setDeleteIntrestById }) => {
    return <div className='flex w-full flex-wrap gap-2 justify-center'>
        {profiles.map((member, index) => {
            const { _id } = member
            return <div key={index}><IntrestCard candidat={member[`${text}`][0]} intrestIds={_id} setDeleteIntrestById={setDeleteIntrestById} /></div>
        })}
    </div>
}



const Reports = ({ sended, recived, user }) => {
    // const printSendedReport = useRef(null);
    const getReport = useRef(null);

    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,
    });

    let profilePhoto, name, city, gotra, matrimonyCode;
    if (user?.userType === 4) {
        profilePhoto = user?.photos?.profilePhoto;
        name = user?.personalInformation?.name;
        city = user?.personalInformation?.city?.cityName;
        gotra = user?.personalInformation?.gotra;
        matrimonyCode = user?.matrimonyCode
    } else {
        profilePhoto = user?.profilePhoto;
        name = user?.name;
        city = user?.city?.name;
        gotra = user?.gotra;
        matrimonyCode = user?.membershipCode
    }

    return <>
        <div className="overflow-x-auto" ref={getReport} >
            {sended.length != 0 && <div >
                <div className=' text-center min-w-full    bg-white shadow-md rounded-lg overflow-hidden border '>
                    {/* <p className=' w-full  text-center   font-bold'>आपके पसंदीदा रिश्ते</p> */}
                    <div className='flex px-5 items-center py-2' >
                        <img className=' w-16  h-16 rounded-2xl' src={Domain + profilePhoto} loading='lazy' />
                        <div className=' ms-5 text-[12px] '>
                            <p className=' flex font-bold'>{name}</p>
                            <p className=' flex font-bold'>{city}</p>
                            <p className=' flex font-bold'>{gotra}</p>
                            <p className=' flex font-bold'>{matrimonyCode}</p>

                        </div>
                    </div>
                </div>
                <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden border">
                    <thead>
                        <tr className="w-full text-sm bg-blue-950 text-white text-left">
                            <th className="py-1   px-2 text-left border-2">क्रमांक</th>
                            <th className="py-1  px-2 text-left border-2">आपके पसंदीदा रिश्ते</th>
                            <th className="py-1 px-2 text-left border-2">विवाह कोड</th>
                            {/* <th className="py-1 px-2 text-left border-2">आपके पसंदीदा रिश्ता</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {sended.map((candidate, index) => {
                            const {
                                personalInformation,
                                matrimonyCode,
                                occupationDetails,
                                kundliDetails,
                                photos,
                                membershipID
                            } = candidate?.sendedByYou[0];

                            const {

                                gotra,
                                name,

                            } = personalInformation;

                            const { profilePhoto } = photos;

                            return (
                                <tr key={index} className="odd:bg-white text-sm even:bg-gray-50">
                                    <td className="py-1 px-2 border-2 ">{index + 1}</td>
                                    <td className="py-1 px-2 border-2 flex min-w-56">
                                        <img className='w-10 h-10 rounded-full me-8' src={Domain + (profilePhoto || 'default.png')} alt={`${name}'s profile`} />
                                        <div className='mt-auto'>
                                            <h2 className='text-black font-bold'>{name}</h2>
                                            <small>{gotra}</small>
                                        </div>
                                    </td>
                                    <td className="py-1 px-2 border-2 ">{matrimonyCode}</td>
                                    {/* Add other table data cells here if necessary */}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {/* <div className=' w-full flex justify-center my-5'>
                    <button onClick={() => { handlePrint(null, () => printSendedReport.current); }} className=' text-white font-bold px-3 py-1 rounded-xl bg-blue-950'>Print Sended Report</button>
                </div> */}
            </div>}


            {/* +++++++++++++++++++++++++ */}
            {recived.length != 0 && <div >

                <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden border">
                    <thead>
                        <tr className="w-full text-sm bg-blue-950 text-white text-left">
                            <th className="py-1   px-2 text-left border-2">क्रमांक</th>
                            <th className="py-1  px-2 text-left border-2">आपको पसंद करने वाले रिश्ते</th>
                            <th className="py-1 px-2 text-left border-2">विवाह कोड</th>
                            {/* <th className="py-1 px-2 text-left border-2">आपके पसंदीदा रिश्ता</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {recived.map((candidate, index) => {
                            const {
                                personalInformation,
                                matrimonyCode,
                                occupationDetails,
                                kundliDetails,
                                photos,
                                membershipID
                            } = candidate?.sendedByYou[0];

                            const {

                                gotra,
                                name,

                            } = personalInformation;

                            const { profilePhoto } = photos;

                            return (
                                <tr key={index} className="odd:bg-white text-sm even:bg-gray-50">
                                    <td className="py-1 px-2 border-2 ">{index + 1}</td>
                                    <td className="py-1 px-2 border-2 flex min-w-56">
                                        <img className='w-10 h-10 rounded-full me-8' src={Domain + (profilePhoto || 'default.png')} alt={`${name}'s profile`} />
                                        <div className='mt-auto'>
                                            <h2 className='text-black font-bold'>{name}</h2>
                                            <small>{gotra}</small>
                                        </div>
                                    </td>
                                    <td className="py-1 px-2 border-2 ">{matrimonyCode}</td>
                                    {/* Add other table data cells here if necessary */}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>}
        </div>
        {(sended.length != 0 || recived.length != 0) ? <div className=' w-full flex justify-center my-5'>
            <button onClick={() => { handlePrint(null, () => getReport.current); }} className=' text-white font-bold px-3 py-1 rounded-xl bg-blue-950'>Download</button>
        </div> : <p>No Interest</p>}
    </>

}
