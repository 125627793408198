import React from 'react'
import callporwal from "../assets/final cards for mayur/committee cards/callbutton.png"
import chatporwal from "../assets/final cards for mayur/committee cards/chatbutton.png"
import messageporwal from "../assets/dashbordBtn/messageporwal.png"
import viewporwal from "../assets/final cards for mayur/committee cards/profilebutton.png"
import GodLogo from "../assets/final cards for mayur/committee cards/samajbutton.png"

import img1 from "../assets/Doctors/1.png"
import img2 from "../assets/Doctors/2.png"
import img3 from "../assets/Doctors/3.png"
import img4 from "../assets/Doctors/4.png"
import img5 from "../assets/Doctors/5.png"
import img6 from "../assets/Doctors/6.png"
import img7 from "../assets/Doctors/7.png"
import img8 from "../assets/Doctors/8.png"
import img9 from "../assets/Doctors/9.png"
import img10 from "../assets/Doctors/10.png"
import img11 from "../assets/Doctors/11.png"
import img12 from "../assets/Doctors/12.png"
import img13 from "../assets/Doctors/13.png"
import img14 from "../assets/Doctors/14.png"
import img15 from "../assets/Doctors/15.png"
import img16 from "../assets/Doctors/16.png"
import img17 from "../assets/Doctors/17.png"
import img18 from "../assets/Doctors/18.png"
import img19 from "../assets/Doctors/19.png"
import img20 from "../assets/Doctors/20.png"
import img21 from "../assets/Doctors/21.png"
import img22 from "../assets/Doctors/22.png"


import img23 from "../assets/Doctors/23.png"
import img24 from "../assets/Doctors/24.png"
import img25 from "../assets/Doctors/25.png"
import img26 from "../assets/Doctors/26.png"
import img27 from "../assets/Doctors/27.png"
import img28 from "../assets/Doctors/28.png"

import img29 from "../assets/Doctors/29.png"
import img30 from "../assets/Doctors/30.png"
import img31 from "../assets/Doctors/31.png"
import img32 from "../assets/Doctors/32.png"
import img33 from "../assets/Doctors/33.png"
import img34 from "../assets/Doctors/34.png"

import img35 from "../assets/Doctors/35.png"
import img36 from "../assets/Doctors/36.png"
import img37 from "../assets/Doctors/37.png"
import img38 from "../assets/Doctors/38.png"
import img39 from "../assets/Doctors/39.png"
// import madhaviDabakara from "../assets/Doctors/madhaviDabakara.png"

import DoctorIcon from "../assets/membercard/DoctorIcon.png"

const images = [
    img1,
    img2,
    img3, 
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
    img33,
    img34,
    img35,
    img36,
    img37,
    img38,
    img39
];

export default function DoctorCard() {


    return (
        <>
            {images.map((img, index) => {
                return (<div key={index} className=' relative w-full   md:w-[350px] xl:w-[450px]   shadow-2xl rounded-3xl h-100 m-3 p-2 xl:pb-4'>
                    <img src={img} alt='docter' loading='lazy' />
                </div>)
            })}

        </>
    )
}
