import React, { useEffect, useState } from 'react'
import { getCitysOnInput } from '../service/Services';

export default function CityDropDown({ options, required, setCity, search, id, setOptions, alredySetCity, label, className, style, ...pros }) {

    const [searchTerm, setSearchTerm] = useState(alredySetCity?.name || alredySetCity?.cityName || "");

    const [isFocused, setIsFocused] = useState(false);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        let filteredData = []
        // if (search == 'city') {
        // console.log(options)

        // if (options.length != 0) {
        filteredData = options?.filter(item =>
            item.name?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        // }

        // } else {
        //     filteredData = options?.filter(item =>
        //         item?.toLowerCase().includes(searchTerm?.toLowerCase())
        //     );
        // }
        setFilteredData(filteredData);
    }, [searchTerm, options]);

    const handleInputChange = e => {
        const { value } = e.target;
        setSearchTerm(e.target.value);
        console.log(value)
        const filteredData = options?.filter(item => item['name']?.toLowerCase().includes(value?.toLowerCase()));
        if (value) {
            setOptions(prev => ({ ...prev, [search]: { 'name': value, 'id': filteredData[0]?.id } }));

        } else {
            setOptions(prev => ({ ...prev, [search]: "" }))
        }
    };

    useEffect(() => {
        let delayDebounceFn;
        delayDebounceFn = setTimeout(async () => {

            console.log('searchTerm', searchTerm)
            try {
                const newsData = await getCitysOnInput({ city: searchTerm })
                // const newsData = await axios.post(`${searchMemberForMemberPage}`, { name, surname, gotra, occupation, city: cityData?.city?.id }, requestOptions);
                console.log(newsData)
                if (newsData?.success) {
                    const data = newsData?.data || [];
                    console.log(data)
                    const uniqueCities = Array.from(new Set(data.map(city => city.cityID)))
                        .map(cityId => {
                            const city = data.find(city => city.cityID === cityId);
                            return {
                                name: city.cityName,
                                id: city.cityID,
                                ...city
                            };
                        });
                    console.log(uniqueCities)
                    // options = uniqueCities

                    setCity(uniqueCities)
                    // setCity(uniqueCities);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                // setLoading(false)
            }
        }, 500);

        return () => {
            clearTimeout(delayDebounceFn);
            // source.cancel('Request canceled because a new request was made');
        };
    }, [searchTerm])



    const handleInputFocus = () => {
        setIsFocused(true);
    };
    const clearSearch = () => {
        setSearchTerm("");
        setIsFocused(false);
    };
    const onSelectValue = (item) => {
        setOptions(pre => ({ ...pre, [search]: item }))
        if ([search] == 'city') {
            setSearchTerm(item['name']);
        } else {
            setSearchTerm(item);
        }
        setIsFocused(false);
    }

    return (
        <div className={`relative  `}>
            <label htmlFor={label} className={`block ${required ? 'required' : ""} capitalize text-sm font-extrabold leading-6 text-gray-900 ${style}`}>
                {label}
            </label>
            <input
                type="text"
                id={label}
                name={label}
                value={searchTerm}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                placeholder="Search..."
                autoComplete="off"
                className={` ${className} `}
                {...pros}
            />
            <div className="absolute inset-y-0   right-0 flex items-center pr-2 mt-5">
                {isFocused ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 cursor-pointer text-gray-400 hover:text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        onClick={clearSearch}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                ) : (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                        />
                    </svg>
                )}
            </div>
            {
                isFocused && (
                    <div className={`absolute w-[90%] md:w-[100%]  h-52 overflow-y-auto z-10 top-full bg-white border border-gray-300 rounded-b-md shadow-lg`} >
                        {filteredData?.map((item, index) => (
                            <div
                                key={index}
                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            >
                                <p onClick={() => onSelectValue(item)}> {search == 'city' ? item['name'] : item}</p>
                            </div>
                        ))}
                    </div>
                )
            }
        </div>
    )
}
