import DoctorPhoto from ".././assets/doctoricon.png"


export const doctors = [
    {
        name: "डॉ.बी के सेठिया",
        specialization: "एम.डी",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.प्रियंका गुप्ता",
        specialization: "एम.डी पंचकर्मा",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.अजय गुप्ता",
        specialization: "डेंटिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.अलोक उदिया",
        specialization: "रेडियोलाजिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.आर.सी.मेहता",
        specialization: "जनरल फिजिशियन",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.बालाराम गुप्ता",
        specialization: "जनरल फिजिशियन",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.श्वेता उदिया",
        specialization: "रेडियोलाजिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.रजनीश गुप्ता",
        specialization: "ओर्थपेडीक सर्जन",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.यतेंद्र पोरवाल",
        specialization: "कार्डियोलॉजिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.मोनिका चौधरी",
        specialization: "पीट्रेडिशन",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.आयुषी गुप्ता",
        specialization: "गयनेकोलॉजिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.नम्रता चौधरी",
        specialization: "ओकोलॉजिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.प्रितेश चौधरी",
        specialization: "ओर्थपेडीक सर्जन",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.गोपाल धनोतिया",
        specialization: "ऑप्टोमेट्रिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.विशाल गुप्ता",
        specialization: "स्पाइन सर्जन",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.गिरीश गुप्ता",
        specialization: "ऑर्थोपैडिक सर्जन",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.सुधांशु धनोतिया",
        specialization: "ऑप्टोमेट्रिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.प्रतिक पोरवाल",
        specialization: "जनरल सर्जन",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.निति गुप्ता",
        specialization: "गयनेकोलॉजिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.सुनील मन्दारिया",
        specialization: "बी.एच.एम.एस",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.विकास डबकरा",
        specialization: "एम.डी आयुर्वेदा",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.सौरभ गुप्ता",
        specialization: "ओर्थोपेडिक सर्जन",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.तुलीका मन्दारिया",
        specialization: "बी.एच.एम.एस",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.माधवी डबकरा",
        specialization: "बी.डी.एस",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.प्रियंका धनोतिया गुप्ता",
        specialization: "डर्मेटोलॉजिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.सलिका मन्दारिया",
        specialization: "गयनेकोलॉजिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.अशोक सेठिया",
        specialization: "एम.डी",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.स्वप्निल मजावदिया",
        specialization: "गेस्ट्रोएन्ट्रोलोगिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.आयुषी धानगढ",
        specialization: "फ़िज़ियोथेरेपिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.सौम्य सेठिया",
        specialization: "एम.डी",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.विनोद पोरवाल",
        specialization: "एम.डी",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.तेजस पोरवाल",
        specialization: "बी ए एम एस",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.सौमित्र सेठिया",
        specialization: "एम.डी",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.ललिता पोरवाल",
        specialization: "ऑप्थेलमोलोजिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.रजनीश पोरवाल",
        specialization: "पिड्रेटीऐशियन",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.पुनिता पोरवाल",
        specialization: "बी.डी.एस",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.अनुपम गुप्ता",
        specialization: "पिड्रेटीऐशियन",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.राघवी गुप्ता",
        specialization: "बी एच एम एस",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.तृप्ति पोरवाल",
        specialization: "फ़िज़ियोथेरेपिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.राकेश गुप्ता",
        specialization: "एम.डी",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.पंखुरी मेहता",
        specialization: "फ़िज़ियोथेरेपिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.निकिता गुप्ता",
        specialization: "फ़िज़ियोथेरेपिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.अर्पित सेठिया",
        specialization: "प्लास्टिक सर्जन",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    },
    {
        name: "डॉ.गरिमा पोरवाल",
        specialization: "रेडिओलॉजिस्ट",
        doctorPhoto: DoctorPhoto,
        mobileNumber: "MobileNumber"
    }
];


