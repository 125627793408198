import logo from './logo.svg';
import './App.css';
import Route from "./Pages/Route"
import { Provider, useDispatch } from "react-redux"
import store from './store/store';
import { createContext, useEffect, useLayoutEffect, useState } from 'react';
import ScrollToTop from './Layouts/ScrollToTOp';
import { loginUserByJwt } from './Redux/authSlicer';
import LoadingModal from './Forms/LoadingModel';
import { useLocation, useNavigate } from 'react-router-dom';


const LoginContext = createContext()
function App() {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  // const { isAuthenticated,setIsAuthenticated,  clearData } = useSessionStorage('myData');
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     event.returnValue = ''; // This line may not work on modern Hashs due to security restrictions
  //   };
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  // window.history.pushState(null, null, window.location.href);
  // window.onpopstate = function (event) {
  //   window.history.go(1);
  // };

  useLayoutEffect(() => {
    const checkUserIsLogin = async () => {
      try {
        setLoading(true)

        const response = await dispatch(loginUserByJwt());
        if (response.error) {
          navigate('/')
        }
        if (!response.payload?.success) {
          console.warn("OTP request unsuccessful:", response.payload?.message || "Unknown error");
          return;
        }
        navigate('/dashboard');
      } catch (error) {
        console.error("Failed to verify OTP:", error);
        navigate('/')
      } finally {
        setLoading(false)
      }
    };
    // checkUserIsLogin()
    // Check if the current route is NOT /matrimonyQr/:matCode
    // console.log("location.pathname", location.pathname)
    if (!location.pathname.startsWith('/business-Registration-Form')) {
      checkUserIsLogin();
    }

  }, [])

  if (isLoading) {
    return <LoadingModal show={isLoading} text={'Loading'} />

  }


  return (
    // <LoginContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
    // <Provider store={store}>
    <>
      <div className="App ">
        <Route />
      </div>
      <ScrollToTop />
    </>
    // </Provider>
    // </LoginContext.Provider>
  );
}

export default App;

export {
  LoginContext
}
