import { json } from "react-router-dom"

const storeAccessToken = (value) => {
    localStorage.setItem("access_token", value)
}

const storePagination = (value) => {
    sessionStorage.setItem("Pagination", JSON.stringify(value))
}
const getPagination = (value) => {
    return JSON.parse(sessionStorage.getItem("Pagination")) || 0
}

export { storeAccessToken, storePagination, getPagination }