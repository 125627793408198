import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { HeaderBoarder } from '../../Components';
import { LagaManagement, UserManagement } from '.';
import Suggesstion from './suggesstion';
import IntrstReport from './intrestreport';
import SamajReport from './samajReport';
import BusinessTable from './Business';

export default function AdminDetails() {

    const { pages } = useParams();
    const isAuthenticated = useSelector(state => state?.auth);
    console.log("admin page ", pages)
    const dispatch = useDispatch()

    const text = {
        UserManagement: "UserManagement",
        LagaManagement: "LagaManagement",
        samajReport: "samajReport",
        businesses: "businesses"


        // members: "समाज सदस्य",
        // mandirdarshan: "श्री लक्ष्मी नरसिंह मंदिर दर्शन",
        // rishtey: "विवाह रिश्ते",
        // bookdharmashala: "बुक धर्मशाला",
        // programs: "कार्यक्रम",
        // bloodbank: "ब्लड बैंक",
        // donations: "डोनेशन लागा",
        // achievements: "उपलब्धियां",
        // samajcalender: "समाज कैलेंडर",
        // digitalcard: "डिजिटल कार्ड",
        // promotion: "एड प्रमोशन",
        // complaints: "शिकायतें",
        // invitations: "निमंत्रण",
        // shoksamachar: "शोक समाचार",
        // jobs: "नौकरी",
        // businessprofile: "बिजनेस प्रोफाइल",
        // registration: "रजिस्ट्रेशन",
        // artical: "आर्टिकल",
    };

    // function renderPages(section) {
    //     switch (section) {
    //         case "UserManagement": return isAuthenticated?.user?.membershipID !== 135 ? <UserManagement /> : "Access denied";
    //         case "LagaManagement": return isAuthenticated?.user?.membershipID !== 135 ? <LagaManagement /> : "Access denied";
    //         case "suggessions": return <Suggesstion />;
    //         case "intrestReport": return isAuthenticated?.user?.membershipID !== 135 ? <IntrstReport /> : "Access denied";
    //         case "samajReport": return isAuthenticated?.user?.membershipID !== 135 ? <SamajReport /> : "Access denied";
    //         // case "members": return <Members />;
    //         // case "mandirdarshan": return <Mandirdarshan />;
    //         // case "rishtey": return <Rishtey />;
    //         // case "bookdharmashala": return <Bookdharmashala />;
    //         // case "programs": return <Programs />;
    //         // case "bloodbank": return <BloodbankPage />;
    //         // case "donations": return <Donations />;
    //         // case "achievements": return <Achievements />;
    //         // case "samajcalender": return <Samajcalender />;
    //         // case "digitalcard": return <Digitalcard />;
    //         // case "promotion": return <Promotion />;
    //         // case "suzhav": return <Suzhav />;
    //         // case "invitations": return <Invitations />;
    //         // case "shoksamachar": return <Shoksamachar />;
    //         // case "jobs": return <Jobs />;
    //         // case "businessprofile": return <Businessprofile />;
    //         // case "registration": return <Registration />;
    //         // case "artical": return <Artical />;
    //         // case "profileEdit": return <MemberProfileEditForm />;
    //         // case "careerGuidance": return <CareerGuidance />;
    //         default:
    //             break;
    //     }
    // }

    function renderPages(section) {
        const membershipID = isAuthenticated?.user?.membershipID;
        const accessDeniedMessage = "Access denied";

        const pagesWithAccessControl = {
            UserManagement: <UserManagement />,
            LagaManagement: <LagaManagement />,
            intrestReport: <IntrstReport />,
            samajReport: <SamajReport />,
            businesses: <BusinessTable />
        };

        const pagesWithoutAccessControl = {
            suggessions: <Suggesstion />,
        };

        if (pagesWithAccessControl[section]) {
            return membershipID !== 135 ? pagesWithAccessControl[section] : accessDeniedMessage;
        }

        return pagesWithoutAccessControl[section] || null;
    }

    return (
        <>
            <HeaderBoarder heading={text[pages]} />
            {renderPages(pages)}
        </>
    )
}
