import React, { useState } from 'react';
import FormHeading from './FormHeading';
import { useForm } from 'react-hook-form';
import { CityDropDown, Input, Textarea } from '../FormsInputes.js';
import { businessRegistration } from '../service/Services.js';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

const BusinessRegistrationForm = () => {
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    // const [business_city, setBusinessCity] = useState({});
    // const [cityOptions, setCityOptions] = useState([]);


    const handleFileChange = (fileName, e) => {
        const file = e.target.files[0]; // Get the first file from the input
        setValue(fileName, file);
    };
    const handleChange = (fieldName, e) => {
        const value = e.target.value;
        setValue(fieldName, value, { shouldValidate: true }); // Update the form value and trigger validation
    };

    const submit = async (data) => {
        try {
            const requiredFields = ['business_company_name', 'business_type_category', 'owner_name', 'owner_contact_number', 'whatsapp_number', 'business_address', 'product_details', 'logo', 'visiting_card'];
            const hasMissingFields = requiredFields.some(field => !data[field]);

            if (hasMissingFields) {
                throw new Error("All required fields must be filled.");
            }
            setLoading(true);

            // setValue('', business_city, { shouldValidate: true }); // Update the form value and trigger validation
            // data['business_city'] = business_city

            const formData = new FormData();
            Object.keys(data).forEach(key => {
                formData.append(key, data[key]);
            });


            console.log(data)

            // console.log('business_city', business_city,)
            // Call API to save the form data here (e.g., addNewBusiness)
            // const response = await addNewBusiness(formData);
            // console.log(response);
            const responce = await businessRegistration(formData)

            console.log("responce", responce)

            // Check if news added successfully
            if (responce?.success) {
                Swal.fire({
                    title: responce.success,
                    text: responce.message,
                    icon: 'success',
                });
                reset()
            }
            // reset(); // Reset form on success
            console.log('Form submitted successfully', data);
        } catch (error) {
            console.error(error);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
            });
            // setError(error.message);
            // alert(error.message); // Show error message
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className='w-100 md:px-10'>
                <form onSubmit={handleSubmit(submit)} className='border-[1px] border-gray-900/10 p-4'>
                    <FormHeading heading={'Business Registration'} />
                    <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">
                        {/* Business Company Name */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Business Company Name / व्यवसाय कंपनी का नाम"
                                placeholder="Enter company name"
                                type="text"
                                required="required"
                                {...register("business_company_name", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 3,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 100,
                                        message: "कृपया 100 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("business_company_name", e)}

                            />
                            {errors.business_company_name && <p className="text-red-500 text-xs mt-1">{errors.business_company_name.message}</p>}
                        </div>

                        {/* Business Type Category */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Business Type Category / व्यवसाय प्रकार श्रेणी"
                                placeholder="Enter business type"
                                required="required"
                                type="text"
                                {...register("business_type_category", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 3,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 50,
                                        message: "कृपया 50 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("business_type_category", e)}

                            />
                            {errors.business_type_category && <p className="text-red-500 text-xs mt-1">{errors.business_type_category.message}</p>}
                        </div>

                        {/* Owner Name */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Owner Name / मालिक का नाम"
                                placeholder="Enter owner name"
                                type="text"
                                required="required"
                                {...register("owner_name", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 3,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 50,
                                        message: "कृपया 50 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("owner_name", e)}

                            />
                            {errors.owner_name && <p className="text-red-500 text-xs mt-1">{errors.owner_name.message}</p>}
                        </div>

                        {/* Owner Contact Number */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Owner Contact Number / मालिक का संपर्क नंबर"
                                placeholder="Enter contact number"
                                type="tel"
                                required="required"
                                {...register("owner_contact_number", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message: "कृपया मान्य 10 अंकों का मोबाइल नंबर दर्ज करें"
                                    }
                                })}
                                onChange={(e) => handleChange("owner_contact_number", e)}

                            />
                            {errors.owner_contact_number && <p className="text-red-500 text-xs mt-1">{errors.owner_contact_number.message}</p>}
                        </div>

                        {/* alternate_contact_number */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Alternate contact number / वैकल्पिक_संपर्क_नंबर"
                                placeholder="Enter contact number"
                                type="tel"
                                {...register("alternate_contact_number", {
                                    // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    // pattern: {
                                    //     value: /^[0-9]{10}$/,
                                    //     message: "कृपया मान्य 10 अंकों का मोबाइल नंबर दर्ज करें"
                                    // }
                                })}
                                onChange={(e) => handleChange("alternate_contact_number", e)}

                            />
                            {errors.alternate_contact_number && <p className="text-red-500 text-xs mt-1">{errors.alternate_contact_number.message}</p>}
                        </div>

                        {/* whatsapp_number */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Whatsapp Number / व्हाट्सएप नंबर"
                                placeholder="Enter Whatsapp number"
                                type="tel"
                                required="required"
                                {...register("whatsapp_number", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message: "कृपया मान्य 10 अंकों का मोबाइल नंबर दर्ज करें"
                                    }
                                })}
                                onChange={(e) => handleChange("whatsapp_number", e)}

                            />
                            {errors.whatsapp_number && <p className="text-red-500 text-xs mt-1">{errors.whatsapp_number.message}</p>}
                        </div>
                        {/* Business Address */}
                        <div className="sm:col-span-6">
                            <Textarea
                                label="Business Address / व्यवसाय पता"
                                placeholder="Enter business address"
                                required="required"
                                {...register("business_address", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 10,
                                        message: "कृपया कम से कम 10 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 250,
                                        message: "कृपया 250 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("business_address", e)}

                            />
                            {errors.business_address && <p className="text-red-500 text-xs mt-1">{errors.business_address.message}</p>}
                        </div>

                        {/* product_details */}
                        <div className="sm:col-span-6">
                            <Textarea
                                label="Product Details / उत्पाद विवरण"
                                placeholder="Enter product details"
                                required="required"
                                {...register("product_details", {
                                    required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 10,
                                        message: "कृपया कम से कम 10 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 2500,
                                        message: "कृपया 2500 अक्षरों से कम भरें"
                                    }
                                })}
                                onChange={(e) => handleChange("product_details", e)}

                            />
                            {errors.product_details && <p className="text-red-500 text-xs mt-1">{errors.product_details.message}</p>}
                        </div>

                        {/* Email ID */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Email ID / ईमेल आईडी"
                                placeholder="Enter email ID"
                                type="email"
                                {...register("email_id", {
                                    // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    pattern: {
                                        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                        message: "कृपया मान्य ईमेल आईडी दर्ज करें"
                                    }
                                })}
                                onChange={(e) => handleChange("email_id", e)}

                            />
                            {errors.email_id && <p className="text-red-500 text-xs mt-1">{errors.email_id.message}</p>}
                        </div>



                        {/* Business City */}
                        {/* <div className="sm:col-span-3">
                            <CityDropDown
                                options={cityOptions}
                                setCity={setCityOptions}
                                search={'city'}
                                id={'business_city'}
                                setOptions={setBusinessCity}
                                required="required"
                                label={"Current city / वर्तमान शहर"}
                                className={' w-full h-9 px-4 py-2 border  border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                                style={' text-black font-bold'}
                                alredySetCity={business_city}
                            />
                        </div> */}

                        {/* {website_link} */}
                        <div className="sm:col-span-3">
                            <Input
                                label="website_link / वेबसाइट लिंक"
                                placeholder="Enter website link"
                                type="text"
                                {...register("website_link", {
                                    // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                    minLength: {
                                        value: 3,
                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                    },
                                    maxLength: {
                                        value: 500,
                                        message: "कृपया 500 अक्षरों से कम भरें"
                                    },
                                    pattern: {
                                        value: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9@:%_\+.~#?&//=]*)?$/,
                                        message: "कृपया एक मान्य वेबसाइट लिंक दर्ज करें (http:// या https:// के साथ)"
                                    }
                                })}
                                onChange={(e) => handleChange("website_link", e)}
                            />
                            {errors.website_link && (
                                <p className="text-red-500 text-xs mt-1">{errors.website_link.message}</p>
                            )}
                        </div>


                        {/* Logo */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Business Logo / व्यवसाय लोगो"
                                placeholder="Upload logo"
                                type="file"
                                required="required"
                                onChange={(e) => handleFileChange("logo", e)}
                            />
                            {errors.logo && <p className="text-red-500 text-xs mt-1">{errors.logo.message}</p>}
                        </div>

                        {/* Visiting Card */}
                        <div className="sm:col-span-3">
                            <Input
                                label="Visiting Card / विजिटिंग कार्ड"
                                placeholder="Upload visiting card"
                                required="required"
                                type="file"
                                onChange={(e) => handleFileChange("visiting_card", e)}
                            />
                            {errors.visiting_card && <p className="text-red-500 text-xs mt-1">{errors.visiting_card.message}</p>}
                        </div>
                    </div>

                    {/* Submit Button */}
                    <div className=" flex justify-center gap-4 mt-6">

                        <button
                            type="submit"
                            disabled={isLoading}
                            className={`py-2 px-4 font-semibold rounded-md text-white bg-blue-500 hover:bg-blue-600 ${isLoading && 'opacity-50 cursor-not-allowed'}`}
                        >
                            {isLoading ? 'Submitting...' : 'Submit'}
                        </button>

                        <Link
                            to="/"
                            className={`py-2 px-4 font-semibold rounded-md text-white bg-blue-500 hover:bg-blue-600 ${isLoading && 'opacity-50 cursor-not-allowed'}`}
                        >
                            Close
                        </Link>
                    </div>

                    {/* Error Message */}
                    {isError && <p className="text-red-500 text-center mt-4">{isError}</p>}
                </form>
            </div>
        </>
    );
};

export default BusinessRegistrationForm;
