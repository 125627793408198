import React from 'react'
import { Link } from 'react-router-dom'
import goSewa from "../../../assets/dashbordBtn/goSewa.png"
import bookExchange from "../../../assets/dashbordBtn/bookExchange.png"
import kapdeDan from "../../../assets/dashbordBtn/kapdedan.png"


export default function SamajSewa() {
    const images = [
        { filename: "goSewa", file: goSewa, url: "/GouSewa" },
        { filename: "bookExchange", file: bookExchange, url: "/BookExchange" },
        { filename: "kapdeDan", file: kapdeDan, url: "/KapadeDan" },
    ]
    return (
        <div>
            <div className='flex flex-wrap  items-start sm:px-5 mb-5  '>
                {images.map((image, index) => (
                    <Link to={'/dashboard' + image.url} key={index}>
                        <img className='h-[6rem] md:h-[9rem]' src={image.file} alt={`${image.filename} ${index + 1}`} loading="lazy" />
                    </Link>
                ))}
            </div>
        </div>

    )
}
