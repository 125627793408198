import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Footer, Header, HeaderBoarder } from '../../../Components'; // Assuming you have these components correctly imported
import Members from '../DashPages/Members.js';
import Committee from '../DashPages/Committee.js';
import Rishtey from "../DashPages/Rishtey.js"
import Mandirdarshan from '../DashPages/Mandirdarshan.js';
import { BloodbankPage, MemberProfileEditForm, Bookdharmashala, Donations, Shoksamachar, Artical, Samajcalender, Achievements, Suzhav, Programs, Promotion, Jobs, Businessprofile, Invitations, CareerGuidance, Registration, Gatividhiya, SamajSewa, GouSewa, BookExchange, KapadeDan, DoctorPage } from '../DashPages/Index.js';
// import { isUserLogin } from '../../../service/Services.js';
// import { setUser } from '../../../Redux/authSlicer.js';
import { useDispatch, useSelector } from 'react-redux';
// import { AdminDetails, UserManagement } from '../../admin/index.js';
import LagaHistory from '../DashPages/LagaHistory.js';
import { MatrimonyForm } from '../../../Forms/index.js';
import BusinessRegistration from '../DashPages/BusinessRegistration.js';


export default function DashDetail() {
    const { section } = useParams();
    const dispatch = useDispatch()
    const isAuthenticated = useSelector(state => state?.auth);

    const text = {
        committee: "कार्यकारिणी समिति",
        members: "समाज सदस्य",
        mandirdarshan: "श्री लक्ष्मी नरसिंह मंदिर दर्शन",
        rishtey: "विवाह रिश्ते",
        bookdharmashala: "बुक धर्मशाला",
        programs: "कार्यक्रम",
        bloodbank: "ब्लड बैंक",
        donations: "डोनेशन लागा",
        achievements: "उपलब्धियां",
        samajcalender: "समाज कैलेंडर",
        doctor: "डाक्टर",
        promotion: "एड प्रमोशन",
        complaints: "शिकायतें",
        invitations: "निमंत्रण",
        shoksamachar: "शोक समाचार",
        jobs: "नौकरी",
        businessprofile: "बिजनेस प्रोफाइल",
        registration: "रजिस्ट्रेशन",
        artical: "आर्टिकल",
        lagahistory: 'समाज लागा विवरण ',
        gatividhiya: "समाज गतिविधि ",
        samajSewa: "समाज सेवा",
        GouSewa: "गौ सेवा",
        BookExchange: "पुस्तक एक्सचेंज",
        KapadeDan: "कपडे दान",
        'business-Registration-Form': "business-Registration-Form"

    };







    //  कमिटी
    //   मेंबर्स
    //   मंदिर दर्शन
    //   विवाह रिश्ते
    //   बुक धर्मशाला
    //   कार्यक्रम
    //   ब्लड बैंक
    //   डोनेशन लागा
    //   उपलब्धियां
    //   समाज कैलेंडर
    //   डिजिटल कार्ड
    //   एड प्रमोशन
    //   शिकायतें
    //   निमंत्रण
    //   शोक समाचार
    //   नौकरी
    //   बिजनेस प्रोफाइल
    //   रजिस्ट्रेशन

    function renderPages(section) {
        switch (section) {
            case "committee": return <Committee />;
            case "members": return <Members />;
            case "mandirdarshan": return <Mandirdarshan />;
            case "rishtey": return <Rishtey />;
            case "bookdharmashala": return <Bookdharmashala />;
            case "programs": return <Programs />;
            case "bloodbank": return <BloodbankPage />;
            case "donations": return <Donations />;
            case "achievements": return <Achievements />;
            case "samajcalender": return <Samajcalender />;
            case "doctor": return <DoctorPage />;
            case "promotion": return <Promotion />;
            case "suzhav": return <Suzhav />;
            case "invitations": return <Invitations />;
            case "shoksamachar": return <Shoksamachar />;
            case "jobs": return <Jobs />;
            case "businessprofile": return <Businessprofile />;
            case "registration": return <Registration />;
            case "artical": return <Artical />;
            case "profileEdit": return (isAuthenticated?.user?.userType === 4) ? <MatrimonyForm /> : <MemberProfileEditForm />;
            case "careerGuidance": return <CareerGuidance />;
            case "lagahistory": return <LagaHistory />;
            case "gatividhiya": return <Gatividhiya />;
            case "samajSewa": return <SamajSewa />;
            case "GouSewa": return <GouSewa />;
            case "BookExchange": return <BookExchange />;
            case "KapadeDan": return <KapadeDan />;
            // case "business-Registration-Form": return <BusinessRegistration />;


            default:
                break;
        }
    }
    return (
        <>

            <HeaderBoarder heading={text[section]} />
            {renderPages(section)}

            {/* <AdminDetails /> */}
        </>
    );
}
