import API from "./API";
// const accessToken = sessionStorage.getItem("access_token")

// console.log(accessToken)
const token = () => {
    const accessToken = localStorage.getItem("access_token")
    return `Bearer ${accessToken}`;
}

export const deleteUser = async (MembershipID) => {
    const authorizationHeader = token();
    try {
        const response = await API.delete(`/admin/delete-user/${MembershipID}`, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return Promise.reject(error.message);
    }
};

export const getMatriMonyByMembershipID = async (Data) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/matrimony/get-MatriMonyByMembershipID', Data, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};
// delete -matrimony - only
export const deleteMemberUserOnly = async (MembershipID) => {
    const authorizationHeader = token();
    try {
        const response = await API.delete(`/admin/delete-member-only/${MembershipID}`, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return Promise.reject(error.message);
    }
};

