import React from 'react'
import { BusinessRegistrationForm } from '../../../Forms'
import { HeaderBoarder } from '../../../Components'

export default function BusinessRegistration() {
    return (
        <div>
            {/* <HeaderBoarder heading={"जांगड़ा पोरवाल व्यापार मेला - 2024 - रजिस्ट्रेशन "} /> */}
            <div className={`HeaderBorder-color w-full h-100 px-3 py-2 text-center flex justify-center items-center text-whit`}>
                <span className='  sm:text-2xl text-white font-extrabold '>जांगड़ा पोरवाल व्यापार मेला - 2024 - रजिस्ट्रेशन  </span>
            </div>

            <BusinessRegistrationForm />
        </div>
    )
}
