import React, { useEffect, useState } from 'react';
import { getSuggession } from '../../service/Services';
import SuggesstionItem from '../../cards/sugetionsCard'; // Assuming you have a separate component for individual suggestions

export default function Suggesstion() {
    const [suggetions, setSuggetions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getAllSuggetions = async () => {
            try {
                const response = await getSuggession();

                // console.log('response', response)

                if (response?.success) {
                    setSuggetions(response?.data);
                }
                // setSuggetions(response?.data);
            } catch (err) {
                setError('An error occurred while fetching suggestions');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        getAllSuggetions();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className=' w-full flex flex-wrap justify-center'>
            {suggetions.map((suggetion, index) => (
                <div key={index}>
                    <SuggesstionItem suggetions={suggetion} />
                </div>
            ))}
        </div>
    );
}
