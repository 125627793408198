import React, { useEffect, useState } from 'react';
import { getBusinesses } from '../../service/Services';
import LoadingModal from '../../Forms/LoadingModel';

const BusinessTable = () => {
    const [businesses, setBusinesses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch the business data
    useEffect(() => {
        const fetchBusinesses = async () => {
            try {
                // const response = await fetch('/api/v1/businesses'); // API endpoint to fetch business data
                // if (!response.ok) {
                //   throw new Error('Failed to fetch business data');
                // }
                // const data = await response.json();
                const data = await getBusinesses();
                setBusinesses(data?.data); // Assuming response follows the format { data: [] }
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchBusinesses();
    }, []);

    // Show loading state
    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    // Show error if occurred
    if (error) {
        return <div>Error: {error}</div>;
    }

    // Display business data in table format
    return (<>
        <LoadingModal show={loading} text='Loading' />

        <div className="container mx-auto my-5 px-4 mb-28">
            <h1 className="text-3xl font-bold mb-6 text-center">Business List</h1>
            <div className="overflow-x-auto">
                <table className="min-w-full border rounded-lg shadow-md">
                    <thead className=" bg-black text-white capitalize">
                        <tr>
                            <th className="px-4 py-3 text-left text-sm font-semibold ">Business ID</th>
                            <th className="px-4 py-3 text-left text-sm font-semibold ">Company Name</th>
                            <th className="px-4 py-3 text-left text-sm font-semibold ">Type Category</th>
                            <th className="px-4 py-3 text-left text-sm font-semibold ">Owner Name</th>
                            <th className="px-4 py-3 text-left text-sm font-semibold ">Contact Number</th>
                            <th className="px-4 py-3 text-left text-sm font-semibold ">WhatsApp Number</th>
                            <th className="px-4 py-3 text-left text-sm font-semibold ">Alternate Contact Number</th>
                            <th className="px-4 py-3 text-left text-sm font-semibold ">Address</th>
                            <th className="px-4 py-3 text-left text-sm font-semibold ">Product Details</th>
                            <th className="px-4 py-3 text-left text-sm font-semibold ">Website</th>
                            <th className="px-4 py-3 text-left text-sm font-semibold ">Email Id</th>
                        </tr>
                    </thead>
                    <tbody>
                        {businesses.map((business) => (
                            <tr key={business._id} className="even:bg-gray-50 hover:bg-gray-100">
                                <td className="px-4 py-3 text-sm border-b text-gray-700">{business.businessID}</td>
                                <td className="px-4 py-3 text-sm border-b text-gray-700">{business.business_company_name}</td>
                                <td className="px-4 py-3 text-sm border-b text-gray-700">{business.business_type_category}</td>
                                <td className="px-4 py-3 text-sm border-b text-gray-700">{business.owner_name}</td>
                                <td className="px-4 py-3 text-sm border-b text-gray-700">{business.owner_contact_number}</td>
                                <td className="px-4 py-3 text-sm border-b text-gray-700">{business.whatsapp_number}</td>
                                <td className="px-4 py-3 text-sm border-b text-gray-700">{business.alternate_contact_number}</td>
                                <td className="px-4 py-3 text-sm border-b text-gray-700">{business.business_address}</td>
                                <td className="px-4 py-3 text-sm border-b text-gray-700">{business.product_details}</td>
                                <td className="px-4 py-3 text-sm border-b text-gray-700">{business.website_link || 'N/A'}</td>
                                <td className="px-4 py-3 text-sm border-b text-gray-700">{business.email_id}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>

    </>
    );
};

export default BusinessTable;
