import React, { useEffect, useState } from 'react'
import { Domain } from '../ApiConfig'
import { replyTosuggestions } from '../service/Services';
import { showErrorAlert, showSuccessAlert } from '../Components/ShowAlertCustome';

export default function SuggetionsCard({ suggetions }) {

    console.log('suggetions', suggetions)
    const [isOpen, setIsOpen] = useState(false);
    const [isReplyOpen, setReplyIsOpen] = useState(false);
    const togglePopup = () => {
        setIsOpen(!isOpen);
    };
    // console.log(suggetions)
    return (
        <>
            <ViwePopup isOpen={isOpen} setIsOpen={setIsOpen} suggetions={suggetions} />
            {/* <Reply isOpen={isReplyOpen} setIsOpen={setReplyIsOpen} suggestions={suggetions} /> */}

            <div className='h-100 m-2 w-[330px]     border-r-5 border   shadow-2xl bg-white text-black  ' >
                <div className={`membercardHeadin bg-[#1E3A8A]    mt-[-0.5px] px-4 py-1 text-center text-white font-bold `}>
                    {suggetions.suggesterName}
                </div>
                <div className="p-2 flex justify-center flex-col ">
                    <div className="  ">
                        <div className="flex min-w-0 gap-x-4 items-center ">
                            {/* <img className="h-[3.5rem] ring-2 ring-orange-400 w-[3.5rem]  flex-none rounded-full bg-blue-400" src={`${Domain + suggetions.profilePhoto.replace(" ", "%20")}`} alt="" loading="lazy" /> */}

                            <div className="min-w-0 flex-auto font-extrabold leading-6 ">
                                <p className="text-[15px] flex  font-bold tracking-tight text-gray-600">
                                    <span className=' w-[100px] me-2 flex justify-between'>तिथि  <span>: </span> </span>
                                    {new Date(suggetions?.entryDatetime).toDateString()}
                                </p>
                                <p className="text-[15px] flex text-start  font-bold tracking-tight text-gray-600">
                                    <span className=' w-[100px] me-2 flex justify-between'>के लिए सुझाव <span >:</span></span>
                                    {suggetions.suggesstionsFor}
                                </p>
                            </div>
                        </div>

                        <div className=' flex justify-around'>
                            <div className=' w-full flex justify-center'>
                                <button onClick={togglePopup} className=' px-4 mt-2 rounded-lg cursor-pointer h-100 bg-orange-600 text-white '> view Details </button>

                            </div>
                            <div className=' w-full flex justify-center'>
                                <p className={`px-4 mt-2 rounded-lg cursor-pointer h-100 ${suggetions?.replyStatus ? 'bg-green-600' : 'bg-yellow-500'}   text-white`}>{suggetions?.replyStatus ? 'Replied' : 'Pending'}</p>
                                {/* <button onClick={() => setReplyIsOpen(pre => !pre)} className=' px-4 mt-2 rounded-lg cursor-pointer h-100 bg-orange-600 text-white '> Reply </button> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

// const Reply = ({ isOpen, setIsOpen, suggestions }) => {
//     // console.log(popupData)
//     // ?.suggesstionId
//     const togglePopup = () => {
//         setIsOpen(!isOpen);
//     };

//     const [reply, setReply] = useState('');

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         const payload = {
//             reply,
//             suggesstionId: suggestions?.suggesstionId,
//         };

//         try {
//             const response = await replyTosuggestions(payload);
//             // Handle success
//             if (response?.success) {
//                 showSuccessAlert("Reply Sended")
//                 togglePopup()
//             } else {
//                 showErrorAlert(response?.message)
//             }
//             console.log('Response:', response?.data);
//         } catch (error) {
//             // Handle error
//             showErrorAlert(error?.message)
//             console.error('Error submitting reply:', error);
//         }
//     };


//     useEffect(() => {
//         // Add suggetions listener when component mounts
//         if (isOpen) {
//             document.body.style.overflow = 'hidden'; // Prevent scrolling on the background page
//         }
//         // Remove suggetions listener when component unmounts
//         return () => {
//             document.body.style.overflow = 'visible'; // Re-enable scrolling on the background page
//         };
//     }, [isOpen]);

//     return (
//         <>
//             {isOpen && (
//                 <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full px-2 bg-black bg-opacity-50 z-50">
//                     <div className="bg-white rounded shadow-2xl w-full md:w-96 overflow-y-auto">

//                         <form onSubmit={handleSubmit} className="shadow-all-sides p-4">
//                             <div onClick={togglePopup} className=' w-full text-end cursor-pointer '> &#x2716;</div>
//                             <div className="flex gap-2 items-center mb-4">
//                                 <img
//                                     className="h-14 w-14 ring-2 ring-orange-400 flex-none rounded-full bg-blue-400"
//                                     src={`${Domain + suggestions?.suggesterPhoto?.replace(" ", "%20")}`}
//                                     alt={`${suggestions?.suggesterName}'s photo`}
//                                     loading="lazy"
//                                 />
//                                 <h1 className="text-2xl font-bold">{suggestions?.suggesterName}</h1>
//                             </div>
//                             <div className="col-span-full">
//                                 <label htmlFor="reply" className="block text-sm font-medium leading-6 text-gray-900">
//                                     Reply to {suggestions?.suggesterName}
//                                 </label>
//                                 <div className="mt-2">
//                                     <textarea
//                                         id="reply"
//                                         name="reply"
//                                         placeholder="Write a reply here"
//                                         rows={3}
//                                         minLength={20}
//                                         maxLength={2000}
//                                         className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                                         value={reply}
//                                         onChange={(e) => setReply(e.target.value)}
//                                         required
//                                     />
//                                 </div>
//                             </div>
//                             <div className="mt-4">
//                                 <button
//                                     type="submit"
//                                     className="w-full px-4 py-2 text-white bg-indigo-600 rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
//                                 >
//                                     Submit
//                                 </button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>


//             )}

//         </>
//     );
// };


const ViwePopup = ({ isOpen, setIsOpen, suggetions, }) => {
    // console.log(popupData)
    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        // Add suggetions listener when component mounts
        if (isOpen) {
            document.body.style.overflow = 'hidden'; // Prevent scrolling on the background page
        }

        // Remove suggetions listener when component unmounts
        return () => {
            document.body.style.overflow = 'visible'; // Re-enable scrolling on the background page
        };
    }, [isOpen]);

    return (
        <>
            {isOpen && (
                <div className="fixed  top-0 left-0 flex items-center px-2 justify-center w-full h-full bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded shadow-2xl w-full  md:w-96  overflow-y-auto">
                        <div className='  min-w-[330px]     border-r-5 border   shadow-2xl bg-white text-black  ' >
                            <div className={`membercardHeadin bg-[#1E3A8A]     text-center text-white font-bold `}>
                                {suggetions.suggesterName}
                            </div>
                            <div className="p-2 flex justify-center flex-col ">
                                <div className=" flex gap-3  ">
                                    {/* <img className=" w-14 h-14 ring-2 ring-orange-400    flex-none rounded-full " src={`${Domain + suggetions.profilePhoto.replace(" ", "%20")}`} alt="" loading="lazy" /> */}
                                    <div className="flex min-w-0 gap-x-4 items-center ">
                                        {/* <div className='border-2 p-[0.5px] mt-3 border-orange-500 rounded-full  '> */}
                                        {/* </div> */}
                                        <div className="min-w-0 flex-auto font-extrabold leading-6 ">
                                            {/* <p className="text-[17px] ms-6 font-extrabold  " ></p> */}
                                            <p className="text-[15px] flex  font-bold tracking-tight text-gray-600">
                                                <span className=' w-[65px] me-2 flex justify-between'>तिथि<span className=' ms-2'>: </span> </span>
                                                {new Date(suggetions?.entryDatetime).toDateString()}
                                            </p>
                                            <p className="text-[15px] flex text-start  font-bold tracking-tight text-gray-600">
                                                <span className=' w-[65px] me-2 flex justify-between'>सुझाव<span >:</span></span>
                                                {suggetions.suggesstionsFor}
                                            </p>
                                            <p className="text-[15px]  flex text-start  font-bold tracking-tight text-gray-600">
                                                <span className=' w-[60px] me-4 flex justify-between'>सुझाव<span className=' ms-2' >:</span></span>
                                                {suggetions.suggesstions}
                                            </p>
                                            {suggetions?.replyStatus && <p className="text-[15px]  flex text-start  font-bold tracking-tight text-gray-600">
                                                <span className=' w-[60px] me-4 flex justify-between'>नाम<span className=' ms-2' >:</span></span>
                                                {suggetions?.replyName}
                                            </p>}
                                            {suggetions?.replyStatus && <p className="text-[15px]  flex text-start  font-bold tracking-tight text-gray-600">
                                                <span className=' w-[60px] me-4 flex justify-between'>जवाब<span className=' ms-2' >:</span></span>
                                                {suggetions?.reply}
                                            </p>}

                                            {/* <p className="text-[15px] flex text-start  font-bold tracking-tight text-gray-600">
                                                <span className=' w-[75px] me-2 flex justify-between'>स्थान <span className=' ms-2' >:</span></span>
                                                {suggetions.placeLocation}
                                            </p> */}
                                            {/* <p className="text-[13px] flex  font-bold tracking-tight text-gray-600"><span className=' w-[50px] me-2 flex justify-between'> व्यवसाय <span>: </span> </span> बिजनेस </p> */}
                                            {/* <div className=' w-full justify-around flex mt-10'>
                                                <img className=" w-14 h-14 ring-2     flex-none  " src={`${Domain + suggetions.photo1.replace(" ", "%20")}`} alt="" loading="lazy" />
                                                <img className=" w-14 h-14 ring-2     flex-none " src={`${Domain + suggetions.photo2.replace(" ", "%20")}`} alt="" loading="lazy" />
                                            </div> */}
                                        </div>


                                    </div>


                                    {/* <div className='flex justify-center bg-gray-200 items-end gap-3 mt-4'>
                <p className='flex flex-col items-center cursor-pointer ' onClick={() => window.location.href = `tel:${9827033232}`}> <img className='h-8' src={callporwal} alt="Call" loading="lazy" /> <span className='text-[12px] leading-7 font-bold tracking-tight text-gray-900'>Call</span></p>
                <p className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `whatsapp://send?phone=${9827033232}`}><img className='h-8' src={chatporwal} alt="Chat" loading="lazy" /> <span className='text-[12px]  leading-7 font-bold tracking-tight text-gray-900'>Chat</span></p>
                <p className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `sms:${9827033232}`}> <img className='h-8' src={messageporwal} alt="Message" loading="lazy" /><span className='text-[12px]  leading-7 font-bold tracking-tight text-gray-900'> Message</span></p>
                <p className='flex flex-col items-center cursor-pointer' onClick={togglePopup}> <img className='h-8' src={viewporwal} alt="View" loading="lazy" /><span className='text-[12px]  leading-7 font-bold tracking-tight text-gray-900'> View</span></p>
            </div> */}
                                    {/* Online indicator */}
                                    {/* <div className="h-3 w-3 bg-green-400 rounded-full"></div> */}

                                </div>

                            </div>
                        </div>

                        <div className="w-full flex justify-center mt-auto"> {/* mt-auto will push the button to the bottom */}
                            <button
                                className="mt-4 bg-[#172553] hover:bg-blue-700 text-white font-bold py-2 px-10 rounded-full"
                                onClick={togglePopup}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>

            )}

        </>
    );
};
