import React, { useState } from 'react'
import { ComingSoon } from '../../../Components'
import { DoctorCard } from '../../../cards'
import { CommitteeCard, MembersCard } from '../../../Components'

import { doctors } from "../../../fakeData/doctor"

export default function Digitalcard() {
    const [doctor, setDoctor] = useState(doctors);

    return (
        <div className='    flex flex-wrap gap-3 justify-center '>

            <div className='flex flex-wrap justify-around mt-5 p-3   w-[100vw] bg-[#151B54]'>
                {/* <Form data={data} label={"सदस्य का नाम"} />
                <Form data={data} label={"समाज मैं पद"} />
                <Form data={data} label={"मोबाइल नंबर"} /> */}
                {/* <Form data={data} label={"lable"} /> */}
            </div>



            <div className='    flex flex-wrap  gap-0 sm:gap-3  justify-center '>
                <DoctorCard />
            </div>
            {/* {members.map((member, index) =>
            (
                <MembersCard user={member} key={index} />
            )
            )} */}
        </div>
    )
}
