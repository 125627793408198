import React, { useEffect, useState } from 'react'
import { AdminButtons } from '../../../Forms'
import { getGatividhi } from '../../../service/Services';
import { GatividhiyaCard } from '../../../cards';
import { Domain } from '../../../ApiConfig';

export default function SamajNews() {
    const [loading, setLoading] = useState(false);
    const [gatividhiya, setgatividhiya] = useState([]);
    // getGatividhi
    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                const res = await getGatividhi()
                console.log(res)
                if (res?.success) {
                    setgatividhiya(res?.data)
                }
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }

        })()
    }, [])

    if (loading) {
        return <p className=' font-bold'>Loading...</p>
    }

    return (
        <div>
            <AdminButtons text="नया समाचार" className=" bg-blue-950 text-white" url="/admin/newGativithi" />

            <div className=' flex flex-wrap gap-3'>

            </div>

            <div className='flex  flex-wrap gap-4 justify-center  my-4'>
                {gatividhiya.map((gatividhi, index) => {

                    const { gatividhiText,
                        gatividhiPhoto,
                        gatividhiDate,
                        gatividhiHeading, } = gatividhi

                    return <GatividhiyaCard heading={gatividhiHeading} gatividhiDate={gatividhiDate} photo={Domain + gatividhiPhoto} text={gatividhiText} />
                })}

            </div>
        </div>
    )
}
