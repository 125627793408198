import React from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Home from './Home/Home';
import { DashHome, DashDetail } from './Dashboard';

import { CommitteeForm, ShokSamacharForm, MatrimonyForm, Achivementform, Suzhavform, DonationForm, DharmashalaBookingFrom, CreatLaga, GatividhiyaForm } from '../Forms';
import LandingPage from './Home/LandingPage';
import HomeRegistationForm from './Home/homeRegistation';
import PrivateRoute from './PrivateRoute';
import PrivacyPolicy from './Home/PrivacyPolicy';
import PublickRout from './PublickRout';
import SamajCalenderForm from '../Forms/samajCalenderForm';
import { AdminDetails, } from './admin';
import AdminRoute from "./AdminRoute"
import MemberQrViewPage from './Home/memberQrViewPage';
import AdminHomePage from './admin/AdminHomePage';
import ThemDetails from './Thems/ThemDetails';
import MatrimonyQrView from './Thems/matrimonyQrView';
import MatrimonyShearQrView from './Thems/MatrimonyShearQrView';
import { MatrimonyRout } from './MatrimonyRout';
import { MatrimonyHomeDashboard } from './matrimonyDashboard.js/Home/home';
import MatrimonyDashDetail from './matrimonyDashboard.js/dashDetails.js/dashDetails';
import MatrimonyAdmin from './admin.matrimonyRout';
import { MatrimonyAdminHomePage } from './matrimonyDashboard.js/admin/matrimonyAdminHomePage';
import { MatrimonyAdminDetails } from './matrimonyDashboard.js/admin';
import BusinessRegistration from './Dashboard/DashPages/BusinessRegistration';




export default function Nav() {

    // const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem('access_token'));

    const isAuthenticated = useSelector(state => state?.auth);
    console.log("isAuthenticated", isAuthenticated)

    return (
        <>
            {/* <PageRefesh /> */}
            <Routes>
                <Route element={<PublickRout />}>
                    <Route path="/" exact element={isAuthenticated?.isLogin ? Navigation(isAuthenticated?.user?.userType, Navigate) : <Home />} />
                    {/* <Route
                        path="/"
                        exact
                        element={isAuthenticated?.isLogin
                            ? <Navigation userType={isAuthenticated?.user?.userType} />
                            : <Home />}
                    /> */}
                    {/* <Route path="/Privacypolicy" element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <PrivacyPolicy />} /> */}

                    <Route path="/first/registration" exact element={isAuthenticated?.isLogin ? <Navigate to="/dashboard" replace /> : <HomeRegistationForm />} />
                    <Route path="/business-Registration-Form" exact element={<BusinessRegistration />} />
                    <Route path="*" exact element={<LandingPage />} />
                </Route>
                {/* Private routes accessible only when authenticated */}

                <Route>
                    <Route path="/Privacypolicy" exact element={<PrivacyPolicy />} />
                    <Route path="/matrimony/registration" exact element={<MatrimonyForm />} />
                    <Route path="/memberQr/:memberShipCode/" exact element={<MemberQrViewPage />} />
                    <Route path="/matrimonyQr/:matCode" element={<MatrimonyQrView />} />
                    <Route path="/QR/:token" element={<MatrimonyShearQrView />} />
                </Route>




                {/* Admin routes accessible only for admin */}
                <Route path="/admin" element={<AdminRoute />}>
                    <Route path=":pages" exact element={<AdminDetails />} />
                    <Route path="" element={<AdminHomePage />} />
                    {/* <Route path=":pages" element={<UserManagement />} /> */}
                    <Route path="addshocknews" element={<ShokSamacharForm />} />
                    <Route path="addDonation" element={<DonationForm />} />
                    <Route path="calender" element={<SamajCalenderForm />} />
                    <Route path="achivement" element={<Achivementform />} />
                    <Route path="creatLaga" element={<CreatLaga />} />
                    <Route path="newGativithi" element={<GatividhiyaForm />} />
                    {/* <Route path="dharmashalaBooking" element={<DharmashalaBookingFrom />} /> */}
                    {/* <Route path="matrimony/registration" element={<MatrimonyForm />} /> */}
                </Route>


                <Route path="/admin/matrimony" element={<MatrimonyAdmin />}>
                    <Route path=":pages" exact element={<MatrimonyAdminDetails />} />
                    <Route path="" element={<MatrimonyAdminHomePage />} />
                    {/* <Route path=":pages" element={<UserManagement />} /> */}
                    {/* <Route path="addshocknews" element={<ShokSamacharForm />} />
                    <Route path="addDonation" element={<DonationForm />} />
                    <Route path="calender" element={<SamajCalenderForm />} />
                    <Route path="achivement" element={<Achivementform />} />
                    <Route path="creatLaga" element={<CreatLaga />} />
                    <Route path="newGativithi" element={<GatividhiyaForm />} /> */}
                    {/* <Route path="dharmashalaBooking" element={<DharmashalaBookingFrom />} /> */}
                    {/* <Route path="matrimony/registration" element={<MatrimonyForm />} /> */}
                </Route>

                {/* Admin routes accessible only for admin */}
                <Route path="/matrimony" element={<MatrimonyRout />}>
                    {/* <Route path=":pages" exact element={<AdminDetails />} /> */}
                    <Route path="" element={<MatrimonyHomeDashboard />} />
                    <Route exact path=":section" element={<MatrimonyDashDetail />} />
                    {/* <Route path=":pages" element={<UserManagement />} /> */}
                    {/* <Route path="addshocknews" element={<ShokSamacharForm />} />
                    <Route path="addDonation" element={<DonationForm />} />
                    <Route path="calender" element={<SamajCalenderForm />} />
                    <Route path="achivement" element={<Achivementform />} />
                    <Route path="creatLaga" element={<CreatLaga />} />
                    <Route path="newGativithi" element={<GatividhiyaForm />} /> */}
                    {/* <Route path="dharmashalaBooking" element={<DharmashalaBookingFrom />} /> */}
                    {/* <Route path="matrimony/registration" element={<MatrimonyForm />} /> */}
                </Route>


                <Route path="/" element={<PrivateRoute />}>
                    {/* /dashboard/Suzhav */}
                    <Route path="/Committee" exact element={<CommitteeForm />} />
                    <Route path="/dashboard" exact element={<DashHome />} />
                    <Route path="/dashboard/:section" exact element={<DashDetail />} />
                    <Route path="/dashboard/dharmashalaBooking" element={<DharmashalaBookingFrom />} />
                    <Route path="/dashboard/newSuzhav" element={<Suzhavform />} />
                    <Route path="/them/:them" element={<ThemDetails />} />

                </Route>

            </Routes>
        </>
    );
}

const Navigation = (userType, navigate) => {
    switch (userType) {
        case 1: return <Navigate to="/dashboard" replace />;
        case 2: return <Navigate to="/dashboard" replace />;
        case 4: return <Navigate to="/matrimony" replace />;
        default:
            break;
    }
};


export const navigation = (userType, navigate) => {

    switch (userType) {
        case 1: return navigate('/dashboard');
        case 2: return navigate('/dashboard');
        case 4: return navigate('/matrimony');



        default:
            break;
    }

}

