import React, { useState } from 'react'

import callporwal from "../assets/membercard/callbutton.png"
import chatporwal from "../assets/membercard/chatbutton.png"
import report from "../assets/membercard/reportbutton.png"
import QR from "../assets/membercard/qr.png"


import messageporwal from "../assets/dashbordBtn/messageporwal.png"
import viewporwal from "../assets/dashbordBtn/viewporwal.png"
// import vishalprofile from "../assets/membersimegas/vishalprofile.jpg"

import { PopupModel } from '.'
import { Domain } from '../ApiConfig'


export default function UserCard({ user, style }) {
    const [isOpen, setIsOpen] = useState(false);
    // console.log(style)
    const togglePopup = () => {
        setIsOpen(!isOpen);
    };
    // console.log("user", user)
    return (
        <>
            <PopupModel isOpen={isOpen} setIsOpen={setIsOpen} popupData={user} />
            <div className=' w-[320px]   shadow-2xl m-4  rounded-b-3xl' >
                <div className='member-card-bg-bottom relative text-center p-2  w-full h-[70px] rounded-t-3xl '>
                    <h2 className=' font-bold'>Member Code : {user.membershipCode}</h2>
                    <img className=" w-[4rem] h-[4rem] left-[40%] absolute  ring-2 rounded-full flex-none bg-blue-400" src={`${user.profilePhoto?.replace(" ", "%20") == 'public/memberprofile/1713277968095-979188414hanuman-render-close-up-4k-hd-3d-ahhiyo7dqfbi8muk.jpg' ? 'https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG.png' : Domain + user.profilePhoto?.replace(" ", "%20")} `} alt="" loading="lazy" />
                </div>
                <div className='comittee-card-bg-color-bottom w-full h-[170px] pt-6 text-center rounded-b-3xl'>
                    <div className=' w-full flex flex-col justify-center'>
                        <h1 className=' text-[#F7F014] font-semibold'>{user.name}</h1>
                        <div className=' flex flex-col items-center  justify-start    text-[12px] font-bold text-white'>

                            <div className=' flex flex-col   justify-start    text-[12px] font-bold text-white'>
                                <div className=' flex '><p className=' w-[100px] text-start ' >गोत्र </p> <p>: {user.gotra}</p> </div>
                                <div className=' flex'><p className=' w-[100px] text-start ' >वर्तमान शहर </p> <p>: {user.city.name}</p> </div>
                                <div className=' flex'><p className=' w-[100px] text-start ' >व्यवसाय </p> <p>: {user.occupation}</p> </div>
                                <div className=' flex  '><p className=' w-[100px] text-start ' >पिताजी का नाम </p> <p>: {user.fatherName}</p> </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-center  items-baseline '>
                        <div className='member-card-bg-bottom flex justify-center  gap-4 px-6 py-2  rounded-full shadow-2xl    items-end '>
                            {/* src={`${Domain + user.profilePhoto.replace(" ", "%20")}`} */}
                            {user?.Qr ? <img className='w-8 h-8' src={`${Domain + user?.Qr}`} alt="QR Code" /> : <img className='w-8 h-8' src={QR} alt="QR Code" />}
                            {user?.mobileNo ? (
                                <>
                                    <img onClick={() => window.location.href = `tel:${user.mobileNo}`} className='w-8 h-8 cursor-pointer' src={callporwal} alt="Call" loading="lazy" />
                                    <img onClick={() => window.location.href = `https://wa.me/${encodeURIComponent(user.whatsappNo)}`} className='w-8 h-8 cursor-pointer' src={chatporwal} alt="Chat" loading="lazy" />
                                </>
                            ) : (
                                <>
                                    <img className='w-8 h-8 cursor-pointer' src={callporwal} alt="Call" loading="lazy" />
                                    <img className='w-8 h-8 cursor-pointer' src={chatporwal} alt="Chat" loading="lazy" />
                                </>
                            )}

                            <img onClick={togglePopup} className='w-8 h-8 cursor-pointer' src={viewporwal} alt="View" loading="lazy" />
                            <img className='w-8 h-8 cursor-pointer' src={report} alt="View" loading="lazy" />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

// {/* <div className='flex flex-wrap  justify-center'>
//                 <div className="w-full md:w-[60vw] xl:w-[30vw]  h-auto sm:h-full p-4 rounded-2xl bg-white shadow-2xl relative">
//                     {/* Car content */}
//                     <div className='flex justify-end mb-2'>
//                         <strong className="text-center w-full sm:w-[17rem] rounded-md text-2xl bg-[#3E4095] text-[#FCF014] p-2 font-mono font-extrabold">Member: Mem0001</strong>
//                     </div>

//                     <div className="member-inner-card relative w-full p-2 rounded-3xl bg-white shadow-2xl ring-1 ring-white">
//                         <img className="md:w-[4.5rem] md:h-[4.5rem] xl:h-[6rem] xl:w-[6rem] w-[5rem] h-[5rem] absolute m-5 xl:top-[-4rem] top-[-1rem] md:top-[-1.2rem]  left-[-0.6rem] ring-2 rounded-full flex-none bg-blue-400" src={`${Domain + user.profilePhoto.replace(" ", "%20")}`} alt="" loading="lazy" />
//                         <div className='flex justify-end md:justify-center m-5 sm:m-0'>
//                             <div className='w-[75%] md:w-[70%] sm:mt-3   md:text-[14px] xl:text-[18px]  md:ms-10    member-card-heading rounded-sm ring-white ring-2 font-extrabold text-center'>
//                                 रामदयाल रतनलाल फरक्या
//                             </div>
//                         </div>

//                         <div className="min-w-0 w-full ms-12  mt-[-2rem] flex flex-col justify-center items-center leading-4">
//                             <div className='my-4 xl:mb-24 mb-[3rem] md:mb-16'>
//                                 <div className="w-full flex-auto ">
//                                     <div className='text-[0.8rem] sm:text-[0.9rem] flex font-bold justify-start tracking-tight text-[#2C292A]'>
//                                         <p className="md:w-[180px] w-[90px] flex justify-between">Gotra</p>
//                                         <p>: Kamaria</p>
//                                     </div>
//                                 </div>

//                                 <div className="w-full flex-auto mt-1">
//                                     <div className='text-[0.8rem] sm:text-[0.9rem]  flex font-bold justify-start tracking-tight text-[#2C292A]'>
//                                         <p className="md:w-[180px] w-[100px] flex justify-between">Age</p>
//                                         <p>: 20 Year</p>
//                                     </div>
//                                 </div>

//                                 <div className="w-full flex-auto mt-1">
//                                     <div className='text-[0.8rem] sm:text-[0.9rem] flex font-bold justify-start tracking-tight text-[#2C292A]'>
//                                         <p className="md:w-[180px] w-[100px] flex justify-between">Height</p>
//                                         <p>: 5.7 Feet</p>
//                                     </div>
//                                 </div>

//                                 <div className="w-full flex-auto mt-1">
//                                     <div className='text-[0.8rem] sm:text-[0.9rem] flex font-bold justify-start tracking-tight text-[#2C292A]'>
//                                         <p className="md:w-[180px] w-[100px] flex justify-between">City</p>
//                                         <p>: Indore-Madhya Pradesh</p>
//                                     </div>
//                                 </div>

//                                 <div className="w-full flex-auto mt-1">
//                                     <div className='text-[0.8rem] sm:text-[0.9rem] flex font-bold justify-start tracking-tight text-[#2C292A]'>
//                                         <p className="md:w-[180px] w-[100px] flex justify-between">Occupation</p>
//                                         <p>: Job-Private Company</p>
//                                     </div>
//                                 </div>

//                                 <div className="w-full flex-auto mt-1">
//                                     <div className='text-[0.8rem] sm:text-[0.9rem] flex font-bold justify-start tracking-tight text-[#2C292A]'>
//                                         <p className="md:w-[180px] w-[100px] flex justify-between">Father's Occupation</p>
//                                         <p>: Job-Private Company</p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='flex absolute rounded-full shadow-2xl ring-white ring-2 p-2 w-60   sm:w-[50%] top-[78%] md:top-[74%] md:left-1/4 left-14 justify-end md:justify-center bg-gray-200 items-end gap-3 mt-4'>
//                             <p className='flex flex-col   sm:ms-5 items-center cursor-pointer'>
//                                 <img className='h-12 sm:h-11' src={callporwal} alt="Call" loading="lazy" />
//                                 <span className='text-xs sm:text-[12px] font-bold tracking-tight text-gray-900'>Call</span>
//                             </p>
//                             <p className='flex flex-col items-center cursor-pointer'>
//                                 <img className='h-12 sm:h-11' src={chatporwal} alt="Chat" loading="lazy" />
//                                 <span className='text-xs sm:text-[12px] font-bold tracking-tight text-gray-900'>Chat</span>
//                             </p>
//                             <p className='flex flex-col items-center cursor-pointer ' onClick={togglePopup}>
//                                 <img className='h-12 sm:h-11' src={viewporwal} alt="View" loading="lazy" />
//                                 <span className='text-xs sm:text-[12px] font-bold tracking-tight text-gray-900'>View</span>
//                             </p>
//                         </div>
//                     </div>
//                     <div className='absolute top-[69%] left-8  md:top-[66%] md:left-[12%] xl:left-[10%] xl:top-[67.5%]'>
//                         <img className=' w-[5.5rem] sm:w-24' src={QR} alt="QR Code" />
//                     </div>
//                     <div className='flex justify-end mt-12 sm:mt-7 cursor-pointer'>
//                         <img className='w-20 md:w-28' src={report} alt="Report" />
//                     </div>
//                 </div>
//             </div> */}
